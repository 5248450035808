import { useState } from 'react';
import { MAIN_ATTACHMENT_KEY } from '../../utils/constants';
import {
  MainPhoto,
  MainPhotoWrapper,
  ElsePhotosWrapper,
  Wrapper,
  ElsePhotoWrapper,
} from './photosDisplayer.styled';
import { usePhotosSorting } from '../../hooks';

function PhotosDisplayer({ photos, setPhotoLoaded }) {
  const [mainPhotoKey, setMainPhotoKey] = useState(MAIN_ATTACHMENT_KEY);
  const { sortedPhotos, photoWidth } = usePhotosSorting(photos);

  return (
    <Wrapper>
      <MainPhotoWrapper>
        {Object.entries(sortedPhotos).map(([key, value]) => {
          const hidePhoto = mainPhotoKey !== key;
          return (
            <MainPhoto
              key={key}
              image={value}
              onLoad={() => setPhotoLoaded(true)}
              onAbort={() => setPhotoLoaded(true)}
              onError={() => setPhotoLoaded(true)}
              hidePhoto={hidePhoto}
            />
          );
        })}
      </MainPhotoWrapper>
      <ElsePhotosWrapper>
        {Object.entries(sortedPhotos).map(([key, value]) => (
          <ElsePhotoWrapper
            key={key}
            onClick={() => setMainPhotoKey(key)}
            selected={key === mainPhotoKey}
            width={photoWidth}
            image={value}
          />
        ))}
      </ElsePhotosWrapper>
    </Wrapper>
  );
}

export default PhotosDisplayer;
