import React from 'react';
import { LoaderContainer, LoaderContainerItem } from './LVLoader.styles';
import { LV_LOADER } from '../../assets';
import { usePlayerLoadingStatus } from '../../utils/threekitHooks';

const loader = LV_LOADER;

const LVLoader = ({ display, isPlayerLoading, currentMode }) => {
  return display || isPlayerLoading ? (
    <LoaderContainer currentMode={currentMode}>
      <img src={loader} />
    </LoaderContainer>
  ) : null;
};

export const LVLoaderItem = ({ display, isSelected }) => {
  const isReady = usePlayerLoadingStatus();
  return isReady && isSelected ? (
    <LoaderContainerItem>
      <img src={loader} />
    </LoaderContainerItem>
  ) : null;
};

export default LVLoader;
