import { styled } from 'styled-components';
import { OOB_APPNAME } from '../../utils/constants';

export const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isLoading'].includes(prop),
})(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    size,
    isLoading,
  }) => {
    return `
          display: ${isLoading ? 'none' : 'flex'};
          flex-direction: column;
          ${isMobile
        ? ''
        : isIpad || isDesktop
          ? `
          flex-direction: row; 
          height: 100vh;
           & > * {
              flex: 1;              
            }
          `
          : ''
      }
      `;
  }
);

export const Section = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    size,
  }) => {
    return `
    &:not(:last-child) {
        border-bottom: 1px solid #E0D7D5;
        ${appName === OOB_APPNAME && isDesktop
        ? `
            border: none;
          `
        : ''
      }
    }
    padding: 20px 10px;
    &:last-child {
        padding-bottom: 25px;
        ${appName === OOB_APPNAME && isDesktop
        ? `
            padding-bottom: 0;
          `
        : ''
      }
    }
    ${isMobile ? '' : isIpad ? 'padding: 0;' : isDesktop ? 'padding: 0;' : ''}
    `;
  }
);

export const ContentWrapper = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    size,
  }) => {
    return `
          display: flex;
          flex-direction: column;
          ${isIpad || isDesktop
        ? `
              min-width:350px;
              margin: 0 auto;`
        : ''
      }
          ${appName === OOB_APPNAME && isDesktop
        ? `
              margin: auto; max-height: 80vh;
              `
        : ''
      }
        `;
  }
);

export const ConfigurationSection = styled(Section)(({
  theme: {
    device: { isMobile, isIpad, isDesktop },
    appName,
  },
}) => {
  return `
          display: flex;
          flex-direction: column;
          gap: 22px;
          overflow-y: auto;
          ${isMobile ? '' : isIpad ? 'gap: 0;' : isDesktop ? 'gap: 0;' : ''}
      `;
});
