import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { PopImage, PopText, PopWrapper } from './popMessage.styles';

function PopMessage({ show, text, onClose, image, ttl = 5000 }) {
  const popRef = useRef(null);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        popRef.current.classList.add('opacity-animation-reverse');
      }, ttl - 1000); // ttl - the time of the animation
      const timer2 = setTimeout(() => {
        onClose();
      }, ttl);
      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    }
  }, [show, onClose, ttl]);
  return (
    show &&
    createPortal(
      <PopWrapper className="opacity-animation-fast" ref={popRef}>
        <PopImage image={image} />
        <PopText>{text}</PopText>
      </PopWrapper>,
      document.body
    )
  );
}

export default PopMessage;
