// photosSortingHook.js
import { useMemo } from 'react';
import { MAIN_ATTACHMENT_KEY } from '../../utils/constants';
import { isEmpty } from '../../utils/functions';

const usePhotosSorting = (photos) => {
  const mainKey = MAIN_ATTACHMENT_KEY;
  const sortedPhotos = useMemo(() => {
    if (isEmpty(photos)) return photos;

    const sorted = {};
    sorted[mainKey] = photos[mainKey];
    Object.entries(photos).forEach(([key, value]) => {
      if (key !== mainKey) {
        sorted[key] = value;
      }
    });
    return sorted;
  }, [photos, mainKey]);

  const photoWidth = useMemo(() => {
    return photos ? 100 / Object.keys(photos).length + '%' : '20%';
  }, [photos]);

  return { sortedPhotos, photoWidth };
};

export default usePhotosSorting;
