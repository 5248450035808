import { createGlobalStyle, styled } from 'styled-components';
import '../font.scss';

export const BasicLayout = createGlobalStyle(
  () => `

  html, body {
    padding: 0;
    margin: 0;
    font-family: 'LouisVuitton_Regular', 'Roboto', sans-serif;
    background-color: #fff;
  }

  * {
    touch-action: pan-x pan-y;
  }

    ::-webkit-scrollbar {
      display: none;
    }

  #deviceToken {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;

  }
}
`
);

export const Version = styled.div(({ version }) => {
  return `
        z-index: 1000;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        padding: 10px;
        color: #fff;
        background-color: #000;
          `;
});

const GlobalStyle = ({ children }) => {
  return (
    <>
      <BasicLayout />
      {children}
    </>
  );
};

export default GlobalStyle;
