export const productPage2DReducers = {
  setToogle(state, action) {
    state.productPage.toogle[1] = action.payload;
  },

  setToogle2(state, action) {
    state.productPage.toogle[2] = action.payload;
  },

  setIsOpen(state, action) {
    state.productPage.isOpen = action.payload;
  },

  setIsRotable(state, action) {
    state.productPage.isRotable = action.payload;
  },

  setPreviousCamera(state, action) {
    state.productPage.previousCamera = action.payload;
  },

  // setImageURL (state, action) {
  //     state.productPage.imageURL = action.payload;
  // }),

  setLastAngle(state, action) {
    state.productPage.lastAngle = action.payload;
  },

  setTotalEngraving(state, action) {
    state.productPage.totalEngraving = action.payload;
  },

  setToggleSlider(state, action) {
    state.productPage.toggleSlider = action.payload;
  },
};
