import React, { useEffect } from 'react';
import Controller from '../../../controller';
import { Wrapper } from './Player2D.styles';
import add2DSpinTool from '../../Tools/add2DSpin';
import { useThreekitInitStatus } from '../../../utils/threekitHooks';
import {
  CLASS_NAME_PREFIX,
  DEFAULT_CLASS_NAME,
  TK_PLAYER_ATTRIBUTE_ID,
  TK_PLAYER_DIV_ID_2D,
} from '../../../utils/constants';

const Player2D = (props) => {
  const { height, width, minHeight, border, children } = Object.assign(
    {
      height: '50vh',
      minHeight: '',
      width: '100%',
      border: 'none',
    },
    props
  );

  const playerReady = useThreekitInitStatus();

  useEffect(() => {
    if (playerReady) add2DSpinTool({ attributeId: TK_PLAYER_ATTRIBUTE_ID });
  }, [playerReady]);

  useEffect(() => {
    Controller.attachPlayerToComponent(TK_PLAYER_DIV_ID_2D);
  }, []);

  const className = `${DEFAULT_CLASS_NAME} ${CLASS_NAME_PREFIX}-player ${props.className}`;

  return (
    <Wrapper
      className={className}
      height={height}
      width={width}
      minHeight={minHeight}
      border={border}
    >
      <div id={TK_PLAYER_DIV_ID_2D} />
      {children}
    </Wrapper>
  );
};

export default Player2D;
