import styled from 'styled-components';

export const PopWrapper = styled.div`
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  width: max-content;
  max-width: 90%;
  border-radius: 15px;
  background-color: #2c2c2ee6;
  backdrop-filter: blur(5.5px);
  color: #fff;
  padding: 10px 20px;
`;

export const PopImage = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${({ image }) => image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const PopText = styled.span`
  font-feature-settings: 'case' on;
  font-size: 15px;
  line-height: 20px;
`;
