import { useQuery } from '@tanstack/react-query';
import { getParams } from '../../utils/functions';
import { useMemo, useState } from 'react';
const location = window.location.host.includes('localhost');
const baseURL = location
  ? 'http://localhost:5000'
  : `https://${window.location.host}`;
const getSavedConfig = async (recipeId) => {
  try {
    const queryParams = new URLSearchParams({
      recipeId,
    }).toString();
    const response = await fetch(
      `${baseURL}/getCustomConfiguration?${queryParams}`
    );

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.error || `HTTP error! status: ${response.status}`
      );
    }

    const data = await response.json();

    if (!data || typeof data !== 'object') {
      throw new Error('Invalid JSON data in response');
    }

    return data || {};
  } catch (error) {
    console.error('Error:', error.message);
    return Promise.reject(error.message);
  }
};

export const useReadableConfiguration = () => {
  const { configId } = getParams();
  const {
    data: config,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['getCustomConfiguration', configId],
    queryFn: () => getSavedConfig(configId),
    staleTime: 300000,
    cacheTime: 600000,
    refetchOnWindowFocus: false,
    enabled: !!configId,
  });

  const [readableConfiguration, setReadableConfiguration] = useState(null);

  const { attachments } = useMemo(() => {
    if (!config) return { attachments: [], productName: '', price: '' };
    return { attachments: config?.attachments || [] };
  }, [config]);

  useMemo(() => {
    if (config && config.metadata) {
      setReadableConfiguration(config.metadata.readableConfiguration);
    }
  }, [config]);

  const lvCustomData = useMemo(() => {
    if (config && Object.values(config?.lvCustomData).length > 0) {
      if (config?.lvCustomData?.priceAmount) {
        config.lvCustomData.price = config.lvCustomData.priceAmount;
        delete config?.lvCustomData?.priceAmount;
      }
      return config?.lvCustomData;
    }
    const params = getParams();
    return {
      sku: params['sku'],
      appName: params['appName'],
      price: params['price'],
      productName: params['productName'],
    };
  }, [config]);

  return {
    readableConfiguration,
    lvCustomData,
    attachments,
    isLoading,
    isError,
    error,
  };
};

export default useReadableConfiguration;
