export const productPage3DReducers = {
  setDisplaySize(state, action) {
    state.productPage.displaySize = action.payload;
  },

  setPlaceHolderError(state, action) {
    state.productPage.placeHolderError = action.payload;
  },

  setNewSize(state, action) {
    state.productPage.newSize = action.payload;
  },

  setCharactRest(state, action) {
    state.productPage.charactRest = action.payload;
  },

  setCurrentEngraving(state, action) {
    state.productPage.currentEngraving = action.payload;
  },

  setPlayerFullScreen(state, action) {
    state.productPage.playerFullScreen = action.payload;
  },

  setCurrentIndex(state, action) {
    state.productPage.currentIndex = action.payload;
  },

  setDisplayAutofocus(state, action) {
    state.productPage.displayAutofocus = action.payload;
  },

  setCurrentSlide(state, action) {
    state.productPage.currentSlideActive = action.payload;
  },

  setSizeAutofocus(state, action) {
    state.productPage.sizeAutofocus = action.payload;
  },
};
