import React from 'react';
import {
  AttributeLabel,
  AttributeThumbnail,
  AttributeValue,
  AttributeWrapper,
  TextSummary,
  BackgroundColor,
} from './ConfigurationSectionContent.styles';

const getAttributeValueComponent = (props) => {
  const { attributeValueDisplayName, thumbnail, thumbnailColor, page, value } =
    { ...props };
  const attributeValue = attributeValueDisplayName || value;
  const hasThumbnail = thumbnail || thumbnailColor;

  return (
    <AttributeValue page={page}>
      {hasThumbnail && thumbnail && (
        <>
          <AttributeThumbnail page={page} src={thumbnail} />
          <TextSummary page={page}>{attributeValueDisplayName}</TextSummary>
        </>
      )}

      {hasThumbnail && !thumbnail && (
        <>
          <BackgroundColor backgroundColor={thumbnailColor} />
          <TextSummary page={page}>{attributeValueDisplayName}</TextSummary>
        </>
      )}

      {!hasThumbnail && <TextSummary page={page}>{attributeValue}</TextSummary>}
    </AttributeValue>
  );
};

function ConfigurationSectionContent({ attributesToDisplay }) {
  const page = 'client';
  const sortAttributesToDisplay = attributesToDisplay
    ?.filter((item) => item[1].uiPositionIndex !== undefined)
    .sort((a, b) => a[1].uiPositionIndex - b[1].uiPositionIndex);
  return sortAttributesToDisplay?.map((el) => {
    const ValueComponent = getAttributeValueComponent(el[1]);
    return ValueComponent && el[1]?.uiVisible ? (
      <AttributeWrapper key={el[0]} page={page}>
        <AttributeLabel page={page}>
          {el[1]?.attributeDisplayName}
        </AttributeLabel>
        {ValueComponent}
      </AttributeWrapper>
    ) : null;
  });
}

export default ConfigurationSectionContent;
