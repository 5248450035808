export const settingsReducers = {
  setConfig(state, action) {
    state.settings.config = action.payload;
  },
  setLoading(state, action) {
    state.settings.isLoading = action.payload;
  },
  setThreekitLoaded(state, _) {
    state.settings.isThreekitLoaded = true;
  },
  setPlayerLoading(state, action) {
    state.settings.isPlayerLoading = action.payload;
  },
  setLanguageState(state, action) {
    state.settings.language = action.payload;
  },
  setAllowInPlayerReorder(state, action) {
    state.settings.allowInPlayerReorder = action.payload;
  },
  setAllowInPlayerSelect(state, action) {
    state.settings.allowInPlayerSelect = action.payload;
  },
  setIsClient(state, action) {
    state.settings.isClient = action.payload;
  },
  setIsChina(state, action) {
    state.settings.isChina = action.payload;
  },
  setUserEmail(state, action) {
    state.settings.userEmail = action.payload;
  },
};

export const setLanguage = (language) => async (dispatch) => {
  if (!language) return;
  const updatedState = window.threekit.controller.setLanguage(language);
  dispatch(settingsReducers.setLanguageState(language));
  dispatch(settingsReducers.setAttributes(updatedState));
};
