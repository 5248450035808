import { configureStore } from '@reduxjs/toolkit';
import threekitReducer from './threekitSlicer/index.js';

const store = configureStore({
  reducer: {
    threekit: threekitReducer,
  },
});

export default store;
