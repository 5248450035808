import { createSlice } from '@reduxjs/toolkit';
// import { CONFIG_CURRENT_MODE } from "../../../../src/config";

import * as threekitReducers from './reducers';

const initialState = {
  settings: {
    config: undefined,
    isLoading: false,
    isThreekitLoaded: true,
    isPlayerLoading: false,
    language: undefined,
    allowInPlayerReorder: true,
    allowInPlayerSelect: true,
    isClient: false,
    isChina: undefined,
    userEmail: '',
  },
  configuration: {
    product: undefined,
    form: undefined,
    attributes: undefined,
    dataDrivenConfiguratorExtensionStatus: { validAttributes: [], sku: '' },
    formValidAttributes: {},
    price: undefined,
    metadata: undefined,
    name: undefined,
    nestedAttributeAddress: undefined,
    nestedAttributes: undefined,
    activeAttribute: undefined,
    dependencies: undefined,
  },
  catalog: {
    categories: [],
    selectedCategory: undefined,
  },
  cart: {
    items: [],
  },
  player: {},
  currentMode: '2D',
  productPage: {
    displaySize: false,
    placeHolderError: false,
    newSize: '',
    charactRest: '',
    currentEngraving: '',
    playerFullScreen: false,
    currentIndex: '',
    displayAutofocus: false,
    currentSlideActive: false,
    sizeAutofocus: true,
    toogle: { 1: false, 2: false },
    isOpen: false,
    previousCamera: '',
    lastAngle: '0',
    totalEngraving: '',
    toggleSlider: false,
    isRotable: true,
  },
  savedConfigurationData: {
    id: '',
    productId: '',
    productVersion: '',
    variant: {},
    metadata: {},
    createdAt: '',
    shortId: '',
    orgId: '',
    thumbnail: '',
    customerId: '',
    scope: '',
    identifier: '',
    attachments: {},
    sceneGraphState: {},
    resumableUrl: '',
    thumbnailUrls: [],
  },
};

export const threekitSlice = createSlice({
  name: 'threekit',
  initialState,
  reducers: {
    ...threekitReducers.cartReducers,
    ...threekitReducers.catalogReducers,
    ...threekitReducers.configurationReducers,
    ...threekitReducers.currentModeReducers,
    ...threekitReducers.playerReducers,
    ...threekitReducers.productPage2DReducers,
    ...threekitReducers.productPage3DReducers,
    ...threekitReducers.settingsReducers,
    ...threekitReducers.savedConfigurationDataReducers,
    ...threekitReducers,
  },
});

export const {
  setConfig,
  setLoading,
  setThreekitLoaded,
  setPlayerLoading,
  setIsClient,
  setIsChina,
  setUserEmail,
  setLanguageState,
  setAllowInPlayerReorder,
  setAllowInPlayerSelect,
  setCategories,
  selectedCategory,
  setProduct,
  setForm,
  setAttributes,
  setValidAttributes,
  setSku,
  setFormValidAttributes,
  setMetadata,
  setPrice,
  setName,
  setNestedAttributeState,
  setNestedAttributesAddressState,
  setActiveAttribute,
  setDependencies,
  setPhase,
  setPlayerConfiguration,
  setTools,
  setPlayerSettings,
  addToCart,
  removeFromCart,
  resetCart,
  setCurrentMode,

  // Player 3D
  setDisplaySize,
  setPlaceHolderError,
  setNewSize,
  setCharactRest,
  setCurrentEngraving,
  setPlayerFullScreen,
  setCurrentIndex,
  setDisplayAutofocus,
  setCurrentSlide,
  setSizeAutofocus,

  // Player 2D
  setToogle,
  setToogle2,
  setIsOpen,
  setPreviousCamera,
  setIsRotable,
  setLastAngle,
  setTotalEngraving,
  setToggleSlider,

  // Saved Configuration Data
  setSavedConfigurationData,
} = threekitSlice.actions;
export default threekitSlice.reducer;

export * from './reducers';
export * from './selectors';
