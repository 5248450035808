export const cartReducers = {
  addToCart(state, action) {
    state.cart.items.push(action.payload);
  },
  removeFromCart(state, action) {
    state.cart.items = state.cart.items.filter(
      (item) => item.id !== action.payload
    );
  },
  resetCart(state) {
    state.cart.items = [];
  },
};
