import styled from 'styled-components';

export const ViewDotsContainer = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
  }) => {
    return `
          display: flex;
          flex-direction: row;
          gap: 15px;
          justify-content: center;
          ${
            isIpad || isDesktop
              ? `
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            padding-bottom: 10px;
          `
              : ''
          }
      `;
  }
);

export const ViewDot = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    isActive,
  }) => {
    return `
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${isActive ? '#000' : '#A6A6A6'};
          cursor: pointer;
          ${
            isDesktop
              ? `
              height: 20px;
              width: 20px;
            `
              : ''
          }
      `;
  }
);
