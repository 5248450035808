import styled from 'styled-components';
import { OOB_APPNAME } from '../../utils/constants';

const LoaderContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['currentMode'].includes(prop),
})(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    currentMode,
  }) => `
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${currentMode === '3D' && !isMobile ? '50vw' : '100vw'};
    height: ${currentMode === '3D' && !isMobile ? '10vw' : '100vh'};
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    z-index: 9999;

    img {
      max-width: 100px;
      max-height: 100px;
    }

    .label {
      font-weight: bold;
      font-size: 12px;
    }
  `
);

const LoaderContainerItem = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
  }) => `
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(230, 230, 230, 0.5);
    height: 100%;
    width: 100%;
    max-height: 245px;
    display: flex;
    justify-content: center;
    align-items: center;  // Center vertically
    ${
      isDesktop &&
      appName === OOB_APPNAME &&
      `
      border: 1px solid transparent;
      border-radius: 100px;
        `
    }

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;  /* Ensure the image maintains its aspect ratio */
      height: auto; /* Ensure the image maintains its aspect ratio */
    }
  `
);
export { LoaderContainer, LoaderContainerItem };
