import { createSelector } from 'reselect';

/*****************************************************
 * Standard Selectors
 ****************************************************/

export const getInitialConfiguration = (state) =>
  state?.threekit?.settings.config?.initialConfiguration;

export const getConfiguration = (state) => state?.threekit?.configuration;

export const getProduct = (state) => state?.threekit?.configuration?.product;

export const getName = (state) => state?.threekit?.configuration?.name;

export const getPrice = (state) => state?.threekit?.configuration?.price;

export const getMetadata = (state) => state?.threekit?.configuration?.metadata;

export const getAllAttributes = (state) =>
  state?.threekit?.configuration?.attributes;

export const getSku = (state) =>
  state?.threekit?.configuration?.dataDrivenConfiguratorExtensionStatus.sku;

export const getFormValidAttributes = (state) =>
  state?.threekit?.configuration?.formValidAttributes;

export const getTotalSteps = (state) =>
  Object?.keys(state?.threekit?.configuration?.formValidAttributes)?.length;

const getInternalAttributeState = (state) => {
  if (!state.threekit.settings.isThreekitLoaded) return undefined;
  return state.threekit.configuration.attributes;
};

export const getNestedAttributes = (state) =>
  state?.threekit?.configuration?.nestedAttributes;

export const getNestedAttributesAddress = (state) =>
  state?.threekit?.configuration?.nestedAttributeAddress;

export const getAttributesArrayConfig = (state) => {
  return {
    allowInPlayerReorder: state?.threekit?.settings?.allowInPlayerReorder,
    allowInPlayerSelect: state?.threekit?.settings?.allowInPlayerSelect,
    activeAttribute: state?.threekit?.configuration?.activeAttribute,
  };
};

export const getActiveAttribute = (state) =>
  state?.threekit?.configuration?.activeAttribute;

export const getDependencies = (state) =>
  state?.threekit?.configuration?.dependencies;

/*****************************************************
 * Complex Selectors
 ****************************************************/

// export const getProductMetadata = (field) =>
//     createSelector([getProduct], (product) => {
//         return (
//             utils.isNotEmpty(product.metadata) &&
//             product.metadata.find((data) => data.name === field).defaultValue
//         );
//     });

export const getForm = createSelector([getConfiguration], (configuration) => {
  if (!configuration) return undefined;
  return configuration.form;
});

export const getAttributes = (attribute) =>
  createSelector(getInternalAttributeState, (attributes) => {
    if (!attributes) return undefined;
    if (!attribute) return attributes;
    return attributes[attribute] || undefined;
  });

// export const getAttributesArray = (arrayLabel) =>
//     createSelector(getInternalAttributeState, (attributes) => {
//         if (!attributes || !arrayLabel?.length) return undefined;
//         const attributesRegExp = new RegExp(`${arrayLabel} [0-9]`);
//         return filterAttributesArray(attributesRegExp, attributes);
//     });

export const getAttributeValues = (attributeName) =>
  createSelector(getInternalAttributeState, (attributes) => {
    if (!attributes || !attributeName) return undefined;
    return attributes[attributeName]?.values || undefined;
  });

export const getReadableValidAttributesFromDataDriven = async () => {
  const readableValidAttributes = [];
  const status = await window.dataDrivenConfiguratorExtension?.getStatus();
  if (!status) return [];
  const validAttributes = status?.validAttributesAndTheirValues;
  for (let validAttribute of validAttributes) {
    if (validAttribute?.children?.length > 0) {
      validAttribute.children.forEach((child) => {
        readableValidAttributes.push(child);
      });
      continue;
    }
    readableValidAttributes.push(validAttribute);
  }
  return readableValidAttributes;
};

export const getValidAttributesNamesFromDataDriven = async () => {
  const validAttributesNames = [];
  const status = await window.dataDrivenConfiguratorExtension?.getStatus();
  if (!status) return [];
  const validAttributes = status?.validAttributesAndTheirValues;
  for (let validAttribute of validAttributes) {
    if (validAttribute?.name) validAttributesNames.push(validAttribute.name);
    if (validAttribute?.children?.length > 0) {
      validAttribute.children.forEach((child) => {
        if (child?.name) validAttributesNames.push(child.name);
      });
    }
  }
  return validAttributesNames;
};

export const getReadableConfigurationWithAttributeType = () =>
  createSelector(getAllAttributes, (attributes) => {
    if (!attributes) return undefined;
    const readableValidAttributesFromDataDriven =
      getReadableValidAttributesFromDataDriven();
    if (
      !readableValidAttributesFromDataDriven ||
      !readableValidAttributesFromDataDriven.length
    )
      return undefined;
    const result = {};
    readableValidAttributesFromDataDriven.forEach(
      ({ type, displayName, ...rest }) => {
        const selected = rest?.values?.find((value) => value?.selected) || rest;
        result[displayName] = {
          ...selected,
          value: type === 'Asset' ? selected?.displayName : selected?.value,
          attributeType:
            rest?.thumbnailPath || rest?.thumbnail ? 'asset' : 'text',
        };
      }
    );
    return result;
  });
