import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import GlobalStyle from './styles/global';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IntlProvider } from 'react-intl';

const queryClient = new QueryClient();

const Project = () => {
  const userLanguage = navigator.language || navigator.userLanguage || 'en-US';

  return (
    <GlobalStyle>
      <IntlProvider locale={userLanguage}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Provider>
      </IntlProvider>
    </GlobalStyle>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Project />);
