import React, { useMemo } from 'react';
import Router from './router/Router';
import { ThemeProvider } from 'styled-components';
import useWindowSize from './utils/threekitHooks/useWindowSize/index.js';
import { getParams } from './utils/functions.js';
import { useReadableConfiguration } from './hooks/index.js';
//import { useDispatch } from 'react-redux';
//import { useParams } from './hooks/index.js';
const App = () => {
  const { isIpad, isDesktop, isMobile } = useWindowSize();
  const { lvCustomData } = useReadableConfiguration();
  const { appName: appNameParams } = getParams();
  const appName = useMemo(() => {
    if (lvCustomData?.appName) {
      return lvCustomData?.appName.toLowerCase();
    }
    return appNameParams?.toLowerCase() || 'catalogios';
  }, [lvCustomData, appNameParams]);
  // const dispatch = useDispatch();
  // const { newParams: config } = useParams();  // working but not used yet

  // useEffect(() => {
  //dispatch(launch(config))
  // }, [dispatch]);

  const theme = useMemo(
    () => ({
      device: { isIpad, isDesktop, isMobile },
      appName: appName,
    }),
    [isIpad, isDesktop, isMobile, appName]
  );

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
};

export default App;
