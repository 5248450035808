import styled from 'styled-components';
import { OOB_APPNAME } from '../../utils/constants';

export const Wrapper = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    width,
  }) => {
    return `
      display: flex;
      flex-direction: column;
      width: 100vw;
      ${isIpad || isDesktop
        ? `border-right: 1px solid #e0d7d5; 
             flex:2;`
        : ''
      }
`;
  }
);

export const MainPhotoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const PlayerWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['playerSelected'].includes(prop),
})(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    playerSelected,
  }) => {
    return `
    opacity: ${!playerSelected ? 0 : 1};
    z-index: ${!playerSelected ? -10 : 0};
    position: ${!playerSelected ? 'absolute' : 'relative'};
    height: 50vh;
    height: 50svh;
    transition: opacity 0.3s ease-in-out;
    ${isMobile
        ? ''
        : isIpad
          ? 'height: 100%;'
          : isDesktop
            ? 'height: 100%;'
            : ''
      }
    `;
  }
);

export const MainPhoto = styled.div.withConfig({
  shouldForwardProp: (prop) => !['hidePhoto'].includes(prop),
})(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    hidePhoto,
    image,
  }) => {
    return `
      width: 100%;
      background-image: url(${image});
      opacity: ${hidePhoto ? 0 : 1};
      position: ${hidePhoto ? 'absolute' : ''};
      z-index: ${hidePhoto ? -10 : 0};
      background-image: url(${image});
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      object-fit: contain;
      height: 50vh;
      height: 50svh;
      top: 0;
      left: 0;
      transition: opacity 0.3s ease-in-out;
      ${isMobile
        ? 'background-size: cover; background-color: #efefef;'
        : isIpad
          ? 'height: 100%;'
          : isDesktop
            ? 'height: 100%;'
            : ''
      }
    `;
  }
);

export const ElsePhotosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;
`;

export const ElsePhotoWrapper = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    width,
    selected,
    image,
  }) => {
    return `
      position: relative;
      max-width: ${width};
      min-width: 100px;
      min-height: 100px;
      cursor: pointer;
      height: 100%;
      display: flex;
      background-image: url(${image});
      background-color: #efefef;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border: 1px solid #e0d7d5;
      border-left: none;
      &:last-child {
        border-right: none;
      }
${appName === OOB_APPNAME && isDesktop ? '' : !selected ? `opacity: 0.5;` : ''};
`;
  }
);

export const Icon360 = styled.img`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
`;
