export const getAttributeGrouping = async (id) => {
  try {
    const firstColumnName = 'Attribute Group';
    const response = await getDatatable(id);

    if (!response) {
      return undefined;
    }

    const data = response?.rows?.reduce((acc, element, rowIdx) => {
      if (rowIdx === 0) {
        return acc;
      }

      const { [firstColumnName]: groupName, ...restOfObject } = element?.value;

      if (!groupName) {
        return acc;
      }

      return {
        ...acc,
        [groupName]: Object.values(restOfObject).filter((el) => el.length > 0),
      };
    }, {});

    return data;
  } catch (error) {
    return undefined;
  }
};

export const fetchThreekit = (configurationId) => {
  let message;
  if (!configurationId) message = 'Requires Configuration Id';
  if (message) return [undefined, { message }];

  return getSavedConfiguration(configurationId);
};

export const fetchThreekitDatatableTranslations = async (
  format = 'json',
  id,
  lng = 'Master'
) => {
  try {
    const firstColumnName = 'Canonical Name';
    const translations = await getDatatable(id);

    if (!translations) {
      return null;
    }


    const translationMap = translations?.rows?.reduce(
      (acc, element, rowIdx) => {
        if (!rowIdx) {
          return acc;
        }

        const { [firstColumnName]: groupName, ...restOfObject } =
          element?.value;

        const translationValue = restOfObject[lng];

        if (!groupName || !translationValue) {
          return acc;
        }

        return {
          ...acc,
          [groupName]: translationValue,
        };
      },
      {}
    );

    return translationMap;
  } catch (error) {
    console.error('Error:', error);
    return undefined;
  }
};

export const getSavedConfiguration = async (configurationId) => {
  let error;
  try {
    if (!configurationId) error = 'Requires Configuration ID';
    if (error) return [undefined, { message: error }];
    const response = await fetch(
      `/getCustomConfiguration?recipeId=${configurationId}`
    );
    const result = await response?.json();
    return result || {};
  } catch (error) {
    console.error('Error fetching product:', error);
    throw error;
  }
};

export const getDatatable = async (id) => {
  try {
    const response = await fetch(`/getDatatable?id=${id}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return JSON.parse(JSON.stringify(data));
  } catch (error) {
    console.error('Error fetching product:', error);
    throw error;
  }
};

export const getProductIdBySku = async (sku) => {
  try {
    const response = await fetch(`/getProductIdBySku?sku=${sku}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data?.configuratorId;
  } catch (error) {
    console.error('Error fetching product:', error);
    throw error;
  }
};
